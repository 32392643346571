import styled, { css } from 'styled-components';
import { BREAKPOINTS } from 'constants/breakpoints';
import { color, spacer, variable, zIndex, breakpointUp } from 'theme/functions';
import Typography from 'components/Typography/Typography';
import ContentfulCallToAction from '../ContentfulCallToAction/ContentfulCallToAction';
import ContentfulImageBlockResponsive from '../ContentfulImageBlockResponsive/ContentfulImageBlockResponsive';
import { Color } from 'types/Color';

const contentSpacingMobile = spacer(100);
const contentSpacing = spacer(200);
const appendedSectionWidth = 70;

export interface ColoredBlockProps {
  backgroundColorHex?: string | Color;
}

export interface StyledBannerImageProps {
  showGradient?: boolean;
}

export const ContentfulBannerWrapper = styled.div`
  width: 100%;
  left: 0;
  position: relative;
  min-height: 300px;
`;

export const StyledBannerImage = styled(ContentfulImageBlockResponsive)<StyledBannerImageProps>`
  ${({ showGradient }) =>
    showGradient &&
    css`
      height: 100%;

      &::after {
        position: absolute;
        content: '';
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        border-radius: ${variable('border-radius')};
        pointer-events: none;
        background: ${variable('image-gradient')};
      }
    `}
`;

export const ContentfulBannerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacer(25)};
  position: absolute;
  left: ${contentSpacingMobile};
  top: ${contentSpacingMobile};
  width: 75%;

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      left: ${contentSpacing};
      top: ${contentSpacing};
      width: 50%;
    `
  )}
`;

export const ContentfulBannerContentButton = styled(ContentfulCallToAction)`
  width: fit-content;
  position: absolute;
  right: ${contentSpacing};
  bottom: ${contentSpacing};
`;

export const ColoredBlock = styled.div<ColoredBlockProps>`
  background-color: ${({ backgroundColorHex }) => backgroundColorHex || color('secondary-200')};
  position: absolute;
  top: 0px;
  left: 0px;
  width: 40%;
  height: 50%;

  &::after {
    content: '';
    position: absolute;
    display: block;
    background-color: ${({ backgroundColorHex }) => backgroundColorHex || color('secondary-200')};
    height: 100%;
    top: 0;
    width: ${2 * appendedSectionWidth}px;
    left: calc(100% - ${1 * appendedSectionWidth}px);
    border-bottom-right-radius: ${appendedSectionWidth}px;
    transform-origin: bottom left;
    transform: skew(-15deg, 0deg);
  }
`;

export const BannerCopy = styled(Typography)`
  z-index: ${zIndex('base')};
`;

export const BannerTitleWrapper = styled.div`
  z-index: ${zIndex('base')};
  width: fit-content;
`;
