import { useQuery } from '@tanstack/react-query';
import useRouter from 'hooks/useRouter';
import { ITag } from 'types/ContentfulTypes';
import { getContentfulPageHome } from 'utils/contentfulUtil';
import { contentfulKeys } from '.';
import { fetchContentForTags, fetchContentfulSlot } from './connector';

const useContentfulSlot = () => {
  const { locale, pathname } = useRouter();
  return useQuery({
    enabled: !!pathname && !!locale,
    queryFn: () => fetchContentfulSlot(pathname, locale),
    queryKey: contentfulKeys.contentfulSlot(pathname, locale),
  });
};

const useContentfulTaggedContent = (tags: ITag[]) => {
  const { locale } = useRouter();
  const mappedTags = tags?.map((tag) => tag?.fields?.tagId);
  return useQuery({
    enabled: !!tags?.length && !!locale,
    queryFn: () => fetchContentForTags(tags, locale),
    queryKey: contentfulKeys.contentfulTaggedContent(mappedTags, locale),
  });
};

const useContentfulHomepage = ({ isPreview = false } = {}) => {
  const { locale } = useRouter();
  return useQuery({
    enabled: !!locale,
    queryFn: () => getContentfulPageHome({ isPreview, locale }),
    queryKey: contentfulKeys.contentfulHomepage(locale),
    select(data) {
      return data?.page;
    },
  });
};

export { useContentfulHomepage, useContentfulSlot, useContentfulTaggedContent };
