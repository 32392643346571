import Typography from 'components/Typography/Typography';
import { BREAKPOINTS } from 'constants/breakpoints';
import { FONT_FAMILY } from 'constants/styling';
import useWindowSize from 'hooks/useWindowSize';
import { useRef } from 'react';
import { IBanner } from 'types/ContentfulTypes';
import { DEFAULT_SIZE_KEY, ImageSizes } from 'types/Image';
import ContentfulPageLink from '../ContentfulPageLink/ContentfulPageLink';
import {
  BannerCopy,
  BannerTitleWrapper,
  ColoredBlock,
  ContentfulBannerContent,
  ContentfulBannerContentButton,
  ContentfulBannerWrapper,
  StyledBannerImage,
} from './ContentfulBannerHomePage.styled';

interface ContentfulBannerHomePageProps {
  className?: string;
  content: IBanner;
  hideCTA?: boolean;
  priority?: boolean;
  sizes?: ImageSizes;
}

const ContentfulBannerHomePage = ({
  className,
  content,
  hideCTA,
  priority,
  sizes = {
    [BREAKPOINTS.XL]: 1176,
    [BREAKPOINTS.LG]: 992,
    [BREAKPOINTS.MD]: 768,
    [BREAKPOINTS.SM]: 576,
    [DEFAULT_SIZE_KEY]: 200,
  },
}: ContentfulBannerHomePageProps) => {
  const titleRef = useRef<HTMLDivElement>(null);
  const { isMobile, isTablet } = useWindowSize();

  if (!content?.fields) {
    return null;
  }

  const { button, color, copy, mediumImage, mobileImage, title, wideImage } = content.fields;

  if (!(mobileImage || mediumImage || wideImage)) {
    return null;
  }

  const hasContent = !!(title || copy || button);
  const titleFontSize = isMobile ? 175 : isTablet ? 200 : 250;
  const titleLineHeight = isMobile ? 225 : isTablet ? 250 : 300;

  const body = (
    <ContentfulBannerWrapper className={className} id={content.sys?.id}>
      <StyledBannerImage
        mediumImage={mediumImage}
        mobileImage={mobileImage}
        objectPosition="bottom left"
        priority={priority}
        sizes={sizes}
        wideImage={wideImage}
      />

      {hasContent && (
        <>
          {(title || copy) && <ColoredBlock backgroundColorHex={color} />}
          <ContentfulBannerContent>
            {title && (
              <BannerTitleWrapper ref={titleRef}>
                <Typography
                  className="contentful-banner-content-title"
                  color="white"
                  fontFamily={FONT_FAMILY.SECONDARY}
                  fontSize={titleFontSize}
                  marginBottom={0}
                  marginTop={0}
                  type="h1"
                >
                  {title}
                </Typography>
              </BannerTitleWrapper>
            )}

            {copy && (
              <BannerCopy
                color="white"
                fontFamily={FONT_FAMILY.SECONDARY}
                fontSize={titleFontSize}
                lineHeight={titleLineHeight}
              >
                {copy}
              </BannerCopy>
            )}
          </ContentfulBannerContent>
          {!hideCTA && button && (
            <ContentfulBannerContentButton className="contentful-banner-content-button" content={button} />
          )}
        </>
      )}
    </ContentfulBannerWrapper>
  );

  if (hideCTA && button?.fields?.link) {
    return <ContentfulPageLink link={button?.fields?.link}>{body}</ContentfulPageLink>;
  }

  return body;
};

export default ContentfulBannerHomePage;
