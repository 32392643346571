import styled, { css } from 'styled-components';
import { color } from '../../theme/functions';
import { Color as ColorType } from '../../types/Color';
import { CURVE_POSITIONS } from './Curve';

export interface StyledCurveProps {
  backgroundColor?: ColorType;
  curvePosition?: (typeof CURVE_POSITIONS)[keyof typeof CURVE_POSITIONS];
  height?: number;
  width?: number;
}

export const StyledCurve = styled.div<StyledCurveProps>`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 200%;
    height: 200%;
    position: absolute;
    border-radius: 50%;

    ${({ curvePosition }) => {
      if (curvePosition === CURVE_POSITIONS.BOTTOM_LEFT) {
        return css<StyledCurveProps>`
          bottom: 0;
          left: 0;
          box-shadow: ${({ width }) => width && `${width / -2}px`} ${({ width }) => width && `${width / 2}px`} 0 0
            ${({ backgroundColor }) => color(backgroundColor)};
        `;
      }

      if (curvePosition === CURVE_POSITIONS.BOTTOM_RIGHT) {
        return css<StyledCurveProps>`
          bottom: 0;
          right: 0;
          box-shadow: ${({ width }) => width && `${width / 2}px`} ${({ width }) => width && `${width / 2}px`} 0 0
            ${({ backgroundColor }) => color(backgroundColor)};
        `;
      }

      if (curvePosition === CURVE_POSITIONS.TOP_LEFT) {
        return css<StyledCurveProps>`
          top: 0;
          left: 0;
          box-shadow: ${({ width }) => width && `${width / -2}px`} ${({ width }) => width && `${width / -2}px`} 0 0
            ${({ backgroundColor }) => color(backgroundColor)};
        `;
      }

      if (curvePosition === CURVE_POSITIONS.TOP_RIGHT) {
        return css<StyledCurveProps>`
          top: 0;
          right: 0;
          box-shadow: ${({ width }) => width && `${width / 2}px`} ${({ width }) => width && `${width / -2}px`} 0 0
            ${({ backgroundColor }) => color(backgroundColor)};
        `;
      }

      return null;
    }}
  }
`;
