import SliderEmbla from 'components/Slider/SliderEmbla/SliderEmbla';
import styled, { css } from 'styled-components';
import { spacer, variable } from 'theme';
import { BREAKPOINTS } from '../../../../../constants/breakpoints';
import { breakpointUp } from '../../../../../theme/functions';
import BoxedLabel from '../../../../BoxedLabel/BoxedLabel';
import ContentfulCarouselItemMapper from '../ContentfulCarouselItemMapper/ContentfulCarouselItemMapper';

export const StyledBannerCarousel = styled.div`
  position: relative;

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      ${variable('border')};
    `,
  )}
`;

export const NavigationItemWrapper = styled.div`
  display: none;
  align-items: center;
  gap: ${spacer(150)};
  padding: 0px ${spacer(150)};
  box-shadow: ${variable('long-box-shadow')};
  border-radius: ${variable('border-radius')};
  height: 10%;
  min-height: 40px;

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      display: flex;
    `,
  )}

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledSlider = styled(SliderEmbla)`
  border-radius: ${variable('border-radius')};
  height: 100%;

  ${breakpointUp(
    BREAKPOINTS.XL,
    css`
      height: 90%;
    `,
  )}

  .embla,
  .embla__viewport,
  .embla__container {
    height: 100%;
  }

  .embla__viewport {
    border-radius: ${variable('border-radius')};
  }
`;

export const NavigationItem = styled(BoxedLabel)`
  width: fit-content;
  white-space: nowrap;
  ${variable('border')};
  transition: all 0.2s;
`;

export const StyledModelMapper = styled(ContentfulCarouselItemMapper)`
  height: 100%;
`;
