import { StyledCurve, StyledCurveProps } from './Curve.styled';

export const CURVE_POSITIONS = {
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_RIGHT: 'bottom-right',
  TOP_LEFT: 'top-left',
  TOP_RIGHT: 'top-right',
} as const;

interface CurveProps extends StyledCurveProps {
  className?: string;
}

const Curve = ({
  backgroundColor = 'white',
  className,
  curvePosition = CURVE_POSITIONS.BOTTOM_RIGHT,
  height = 120,
  width = 80,
}: CurveProps) => (
  <StyledCurve
    backgroundColor={backgroundColor}
    className={className}
    curvePosition={curvePosition}
    height={height}
    width={width}
  />
);

export default Curve;
