import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ATP_MESSAGES } from '../../../constants/atp';
import { Icon, ICON_NAMES } from '../../../constants/icon';
import { ButtonColor, ButtonSize, FontWeight } from '../../../constants/styling';
import usePlatform from '../../../hooks/usePlatform';
import Button from '../../Button/Button';
import ButtonLeaf from '../../ButtonLeaf/ButtonLeaf';
import Typography from '../../Typography/Typography';

const BUTTON_ID = 'add-to-cart-button';

interface AddToCartButtonProps {
  addToCartMessage?: string;
  canBeDisabled?: boolean;
  canBeSold?: boolean;
  className?: string;
  color?: ButtonColor;
  fontWeight?: FontWeight;
  fullwidth?: boolean;
  icon?: Icon;
  isLoadingWithTimeOut?: boolean;
  label?: string;
  loading?: boolean;
  onAddToCart?: () => void;
  showIcon?: boolean;
  size?: ButtonSize;
  withLabel?: boolean;
}

const AddToCartButton = ({
  addToCartMessage,
  canBeDisabled = true,
  canBeSold = true,
  className,
  color = 'primary',
  fontWeight = 'bold',
  fullwidth,
  icon = ICON_NAMES.CART_PLUS,
  isLoadingWithTimeOut,
  label,
  loading: isLoadingProp,
  onAddToCart,
  showIcon,
  size = 'large',
  withLabel = true,
  ...props
}: AddToCartButtonProps) => {
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);

  // Disable ATC-button when prop is true
  const isDisabledBtnState = canBeDisabled && (addToCartMessage !== ATP_MESSAGES.BUTTON_STOCK || loading || !canBeSold);

  const { isKrefel } = usePlatform();

  const buttonText = label || addToCartMessage;

  const handleAddToCart = async () => {
    setLoading(true);
    if (onAddToCart) {
      await onAddToCart();
    }
    isLoadingWithTimeOut ? setTimeout(() => setLoading(false), 2000) : setLoading(false);
  };

  useEffect(() => {
    setLoading(!!isLoadingProp);
  }, [isLoadingProp]);

  if (isKrefel && !withLabel) {
    return (
      // @ts-ignore
      <ButtonLeaf
        aria-label={formatMessage({ id: 'atc_button_leaf_label' })}
        className={className}
        color={color}
        disabled={isDisabledBtnState}
        flipIcon="horizontal"
        icon={ICON_NAMES.CART}
        iconSize={150}
        id={BUTTON_ID}
        isCustomIcon
        loading={loading}
        onClick={(e) => {
          e.preventDefault();
          handleAddToCart();
        }}
      />
    );
  }

  return (
    // @ts-ignore
    <Button
      aria-label={formatMessage({ id: 'atc_button_label' })}
      className={className}
      color={color}
      disabled={isDisabledBtnState}
      flipIcon="horizontal"
      fontWeight={fontWeight}
      fullwidth={fullwidth}
      icon={showIcon ? icon : undefined}
      iconPosition="before"
      iconSize={150}
      id={BUTTON_ID}
      isCustomIcon
      loading={loading}
      onClick={(e) => {
        e.preventDefault();
        handleAddToCart();
      }}
      rounded={withLabel}
      size={size}
      {...props}
    >
      {withLabel && !!buttonText && (
        // @ts-ignore
        <Typography color="">{formatMessage({ id: buttonText })}</Typography>
      )}
    </Button>
  );
};

export default AddToCartButton;
