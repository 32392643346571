import dynamic from 'next/dynamic';
import { IPageHome } from 'types/ContentfulTypes';
import ContentfulBannerCarousel from '../ContentfulFields/ContentfulBannerCarousel/ContentfulBannerCarousel';
import ContentfulLayout from '../ContentfulLayout/ContentfulLayout';

const ContentfulInteractiveVisual = dynamic(
  () => import('../ContentfulFields/ContentfulInteractiveVisual/ContentfulInteractiveVisual'),
);
const ContentfulModelMapper = dynamic(() => import('../ContentfulModelMapper/ContentfulModelMapper'));

interface ContentfulPageHomeProps {
  content: IPageHome;
}

const ContentfulPageHome = ({ content }: ContentfulPageHomeProps) => {
  const { fields } = content;

  const pageContent = fields?.content;
  const interactiveVisual = fields?.interactiveVisual;
  const banners = fields?.banners;

  return (
    <ContentfulLayout>
      {interactiveVisual && <ContentfulInteractiveVisual interactiveVisual={interactiveVisual} />}
      {banners && <ContentfulBannerCarousel banners={banners} priority />}
      {fields && pageContent?.map((item) => <ContentfulModelMapper key={item.sys.id} content={item} />)}
    </ContentfulLayout>
  );
};

export default ContentfulPageHome;
