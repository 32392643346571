import styled, { css } from 'styled-components';
import { color, spacer, breakpointUp } from 'theme';
import { BREAKPOINTS } from 'constants/breakpoints';
import { variable } from 'theme/functions';
import { handleStylingValue } from 'utils/textUtil';
import Icon from 'components/Icon/Icon';
import ContentfulBannerContentBlock from '../ContentfulBannerContentBlock/ContentfulBannerContentBlock';
import ContentfulImageBlockResponsive from '../ContentfulImageBlockResponsive/ContentfulImageBlockResponsive';

interface BannerCardImageProps {
  minHeight: string | number;
  mobileWidth: number;
}

export const BannerCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  box-shadow: ${variable('long-box-shadow')};

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      flex-direction: column;
    `
  )}
`;

export const BannerCardImage = styled(ContentfulImageBlockResponsive)<BannerCardImageProps>`
  height: 100%;
  width: ${({ mobileWidth }) => `${mobileWidth}px`};
  position: relative;

  ${breakpointUp(
    BREAKPOINTS.MD,
    css<BannerCardImageProps>`
      min-height: ${({ minHeight }) => handleStylingValue(minHeight)};
      width: 100%;
    `
  )}
`;

export const BannerIcon = styled(Icon)`
  position: absolute;
  right: ${spacer(50)};
  bottom: ${spacer(50)};

  &:hover {
    cursor: pointer;
    color: ${color('primary-400')};
    fill: ${color('primary-400')};
  }
`;

export const StyledBannerContentBlock = styled(ContentfulBannerContentBlock)`
  padding: ${spacer(125)};
  height: 100%;
  justify-content: flex-start;
`;

export const StyledCurveWrapper = styled.div`
  position: absolute;
  bottom: 100%;
  right: 0;
`;
