import CategoryNavigationOverlay from 'components/General/CategoryNavigationOverlay/CategoryNavigationOverlay';
import { memo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useIntl } from 'react-intl';
import { Category } from 'types/Category';
import { IBannerCarousel } from 'types/ContentfulTypes';
import ContentfulBannerCarouselWide from './ContentfulBannerCarouselWide/ContentfulBannerCarouselWide';

interface ContentfulBannerCarouselProps {
  banners: IBannerCarousel[];
  priority?: boolean;
}

const ContentfulBannerCarousel = ({ banners, priority }: ContentfulBannerCarouselProps) => {
  const { formatMessage } = useIntl();
  const [activeCategory, setActiveCategory] = useState<Category>(); // Handle the active category here to avoid the gap between the two panels closing the active category

  if (banners?.length < 1) {
    return null;
  }

  const collapse = () => {
    if (activeCategory) {
      setActiveCategory(undefined);
    }
  };

  return (
    <div className="relative grid grid-cols-4 gap-8" onMouseLeave={collapse}>
      <div className="hidden xl:block">
        <CategoryNavigationOverlay
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          slogan={formatMessage({
            id: 'header_main_nav_categories_slogan',
          })}
        />
      </div>

      {banners?.[0] && (
        <div className="col-span-full xl:col-span-3">
          <ContentfulBannerCarouselWide content={banners[0]} priority={priority} />
        </div>
      )}
    </div>
  );
};

export default memo(ContentfulBannerCarousel, isEqual);
