import styled, { css } from 'styled-components';
import { BREAKPOINTS } from '../../../../constants/breakpoints';
import {
  breakpointDown,
  breakpointUp,
  spacer,
} from '../../../../theme/functions';
import Box from '../../../Box/Box';
import Flex from '../../../Flex/Flex';
import AddToCartButton from '../../../Product/AddToCartButton/AddToCartButton';
import ContentfulImageBlock from '../ContentfulImageBlock/ContentfulImageBlock';

const productInfoIndent = spacer(100);

export const BannerImage = styled(ContentfulImageBlock)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const BannerContent = styled(Flex)`
  position: relative;
  margin: 0px ${spacer(200)};
  flex-direction: column;
  align-items: center;
  gap: ${spacer(150)};

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      flex-direction: row;
      height: 100%;
    `
  )};
`;

export const StyledAddToCartButton = styled(AddToCartButton)`
  ${breakpointDown(
    BREAKPOINTS.MD,
    css`
      margin: 0px calc(-1 * ${productInfoIndent});
      width: calc(100% + ${productInfoIndent} * 2);
    `
  )};
`;

export const StyledImageWrapper = styled(Box)`
  height: 190px;
  width: 150px;
  position: relative;

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      height: 280px;
      width: 200px;
    `
  )};
`;

export const ProductInfoWrapper = styled(Flex)`
  flex-direction: column;
  margin-left: ${productInfoIndent};
  margin-right: ${productInfoIndent};
`;
