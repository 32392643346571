import { StyledBannerContentBlock } from './ContentfulBannerContentBlock.styled';
import { FlexProps } from 'components/Flex/Flex';

interface ContentfulBannerContentBlockProps extends FlexProps {
  className?: string;
}

const ContentfulBannerContentBlock = ({
  children,
  className,
  flexDirection = 'column',
  gap = 150,
  justifyContent = 'space-between',
  padding = 125,
  position = 'relative',
}: ContentfulBannerContentBlockProps) => (
  <StyledBannerContentBlock
    className={className}
    flexDirection={flexDirection}
    justifyContent={justifyContent}
    padding={padding}
    position={position}
    gap={gap}
  >
    {children}
  </StyledBannerContentBlock>
);

export default ContentfulBannerContentBlock;
