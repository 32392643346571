import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import ContentfulBannerCard from '../../ContentfulBannerCard/ContentfulBannerCard';
import { BANNER_VARIANT_TYPES, BannerVariantType, CONTENTFUL_TYPES } from '../../types';
import ContentfulProductBanner from '../../ContentfulProductBanner/ContentfulProductBanner';
import ContentfulProductBannerCard from '../../ContentfulProductBannerCard/ContentfulProductBannerCard';
import ContentfulBannerHomePage from '../../ContentfulBannerHomePage/ContentfulBannerHomePage';
import { IBanner, ITileProduct } from 'types/ContentfulTypes';

interface ContentfulCarouselItemMapper {
  content?: ITileProduct | IBanner;
  priority?: boolean;
  variant?: BannerVariantType;
}

const ContentfulCarouselItemMapper = ({
  content,
  priority,
  variant = BANNER_VARIANT_TYPES.WIDE,
  ...props
}: ContentfulCarouselItemMapper) => {
  if (!content?.fields) {
    return null;
  }

  const contentType = content.sys.contentType.sys.id;

  const getComponent = () => {
    if (contentType === CONTENTFUL_TYPES.TILE_PRODUCT) {
      if (variant === BANNER_VARIANT_TYPES.WIDE) {
        return <ContentfulProductBanner content={content as ITileProduct} {...props} />;
      }

      return <ContentfulProductBannerCard {...props} content={content as ITileProduct} priority={priority} />;
    }

    if (contentType === CONTENTFUL_TYPES.BANNER) {
      if (variant === BANNER_VARIANT_TYPES.WIDE) {
        return <ContentfulBannerHomePage {...props} content={content as IBanner} priority={priority} />;
      }

      return <ContentfulBannerCard {...props} content={content as IBanner} hasCurve priority={priority} />;
    }

    return null;
  };

  return <ErrorBoundary message="ERROR: This will be hidden when live. Please report!">{getComponent()}</ErrorBoundary>;
};

export default ContentfulCarouselItemMapper;
