import { AriaRole } from 'react';
import StyledBoxedLabel, { StyledBoxedLabelProps } from './BoxedLabel.styled';

interface BoxedLabelProps extends StyledBoxedLabelProps {
  className?: string;
  onClick?: () => void;
  role?: AriaRole;
  tabIndex?: number;
}

const BoxedLabel = ({
  background = 'white',
  borderRadius = 0,
  borderWidth = 1,
  children,
  className,
  outlineColor = 'whisper-200',
  outlined = true,
  role,
  spacerX = 150,
  spacerY = 100,
  tabIndex,
  ...props
}: BoxedLabelProps) => (
  <StyledBoxedLabel
    background={background}
    borderRadius={borderRadius}
    className={className}
    outlineColor={outlineColor}
    outlined={outlined}
    spacerX={spacerX}
    spacerY={spacerY}
    borderWidth={borderWidth}
    role={role}
    tabIndex={tabIndex}
    {...props}
  >
    {children}
  </StyledBoxedLabel>
);

export default BoxedLabel;
