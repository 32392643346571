import styled, { css } from 'styled-components';
import { color, breakpointUp, spacer } from 'theme';
import { BREAKPOINTS } from 'constants/breakpoints';
import Link from 'components/Link/Link';
import Price from 'components/Price/Price';
import ContentfulImageBlockResponsive from '../ContentfulImageBlockResponsive/ContentfulImageBlockResponsive';
import ContentfulPageLink from '../ContentfulPageLink/ContentfulPageLink';

const imageSpacing = spacer(100);

interface StyledProductLinkProps {
  height?: string;
}

export const BackgroundImage = styled(ContentfulImageBlockResponsive)`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const StyledProductLink = styled(Link)<StyledProductLinkProps>`
  position: relative;
  width: 150px;
  margin: ${imageSpacing} auto;
  flex-grow: 1;
  height: 100%;
  min-height: calc(${({ height }) => height} - (2 * ${imageSpacing}));

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      width: 100%;
    `
  )};
`;

const linkStyling = css`
  && {
    text-decoration: underline;
    color: ${color('primary-300')};

    &:hover {
      color: ${color('primary-400')};
    }
  }
`;

export const BannerContentfulLink = styled(ContentfulPageLink)`
  ${linkStyling}
`;

export const BannerContentLink = styled(Link)`
  ${linkStyling}
`;

export const StyledPrice = styled(Price)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: ${spacer(50)};
`;
