import Icon from 'components/Icon/Icon';
import { ICON_NAMES } from 'constants/icon';
import useMouseMoveRightDelay from 'hooks/useMouseMoveRightDelay';
import { useRef } from 'react';
import { useIntl } from 'react-intl';
import { Category } from 'types/Category';
import { Navigation } from 'types/Navigation';
import { cn } from 'utils/cn';
import { StyledLink, StyledMainCategoryLinkWrapper, StyledSlogan } from './CategoryDropDownLeft.styled';

interface CategoryDropDownLeftProps {
  activeL1Category?: Category;
  className?: string;
  navigation: Navigation;
  setCurrentL1Category: (category: Category) => void;
  toggleCategoryDropDown?: () => void;
}

const CategoryDropDownLeft = ({
  activeL1Category,
  className,
  navigation,
  setCurrentL1Category,
  toggleCategoryDropDown,
}: CategoryDropDownLeftProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { onMouseEnter, onMouseLeave } = useMouseMoveRightDelay(dropdownRef);
  const { formatMessage } = useIntl();

  const slogan = formatMessage({
    id: 'header_main_nav_categories_slogan',
  })?.trim();

  return (
    <div className={cn('flex flex-col', className)} ref={dropdownRef}>
      {navigation?.categories?.map((category) => (
        <StyledMainCategoryLinkWrapper
          key={category.code}
          active={activeL1Category?.code === category?.code}
          onMouseEnter={() => onMouseEnter(() => setCurrentL1Category(category))}
          onMouseLeave={() => onMouseLeave()}
        >
          {category?.clickable ? (
            <StyledLink hoverTextColor="primary-400" url={category?.url} onClick={toggleCategoryDropDown}>
              {category.name}
            </StyledLink>
          ) : (
            category.name
          )}
          <Icon type="fa" name={ICON_NAMES.ANGLE_RIGHT} size={100} />
        </StyledMainCategoryLinkWrapper>
      ))}

      {!!slogan && <StyledSlogan type="p" marginBottom={0} html={slogan} />}
    </div>
  );
};

export default CategoryDropDownLeft;
