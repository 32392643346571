import PageLayout from 'components/PageLayout';
import ArticleSchemeScript from 'components/Seo/SeoSchemeScripts/ArticleSchemeScript/ArticleSchemeScript';
import { useContentfulHomepage } from 'features/contentful/queries';
import { ReactNode, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { isPageHome } from 'types/Contentful';
import ContentfulPageHome from '../components/Contentful/ContentfulPageHome/ContentfulPageHome';
import SEO from '../components/Seo/SEO';
import { ANALYTICS_PAGE_TYPES } from '../constants/analytics';
import useAuthResolver from '../hooks/useAuthResolver';
import { pathnames } from '../i18n/pathnames';
import { createCMSProductImpression, createCriteoHomePageImpression } from '../utils/analyticsProductDataUtil';
import { pushPageType } from '../utils/analyticsUtil';
import { getSeoData } from '../utils/seoUtil';

const IndexContainer = () => {
  const { formatMessage } = useIntl();
  const { authResolved, user } = useAuthResolver();
  const { data: contentfulPage } = useContentfulHomepage();

  useEffect(() => {
    window.productOverview = [];

    if (window?.productOverview?.length) {
      const gtmData = createCMSProductImpression(window.productOverview, 'homepage');
      const criteoData = user?.uid && createCriteoHomePageImpression(user?.uid);
      if (gtmData) {
        window?.dataLayer?.push(gtmData);
        window.productOverview = [];
      }
      if (criteoData) {
        window?.dataLayer?.push(criteoData);
      }
    }

    pushPageType(ANALYTICS_PAGE_TYPES.HOME, pathnames.INDEX);
  }, [authResolved]);

  if (!contentfulPage || !isPageHome(contentfulPage)) {
    return null;
  }
  const { seoDescription, seoTitle } = getSeoData(contentfulPage?.fields, formatMessage, 'Home');

  return (
    <SEO description={seoDescription} title={seoTitle}>
      <ArticleSchemeScript date={contentfulPage?.sys?.updatedAt} seoTitle={seoTitle ?? ''} />
      <main>{contentfulPage && <ContentfulPageHome content={contentfulPage} />}</main>
    </SEO>
  );
};

export default IndexContainer;

IndexContainer.getLayout = function getLayout(page: ReactNode) {
  return <PageLayout>{page}</PageLayout>;
};
