import styled, { css } from 'styled-components';
import { spacer } from 'theme';
import { color } from '../../theme/functions';
import { ReactNode } from 'react';
import { Color } from 'types/Color';
import { Spacer } from '../../constants/styling';

export interface StyledBoxedLabelProps {
  background?: Color;
  borderRadius?: Spacer;
  borderWidth?: number;
  children: ReactNode;
  fullWidth?: boolean;
  outlineColor?: Color;
  outlined?: boolean;
  spacerX?: Spacer;
  spacerY?: Spacer;
}

export default styled.div<StyledBoxedLabelProps>`
  line-height: normal;
  display: flex;
  align-items: center;
  width: ${({ fullWidth }) => fullWidth && '100%'};

  ${({ spacerX }) =>
    !!spacerX &&
    css`
      padding-left: ${spacer(spacerX)};
      padding-right: ${spacer(spacerX)};
    `}
  ${({ spacerY }) =>
    !!spacerY &&
    css`
      padding-top: ${spacer(spacerY)};
      padding-bottom: ${spacer(spacerY)};
    `}
    ${({ borderRadius }) =>
    !!borderRadius &&
    css`
      border-radius: ${spacer(borderRadius)};
    `}
    ${({ borderWidth, outlineColor, outlined }) =>
    outlined &&
    !!outlineColor &&
    borderWidth &&
    css`
      border-width: ${borderWidth}px;
      border-style: solid;
      border-color: ${color(outlineColor)};
    `}
    ${({ background }) =>
    !!background &&
    css`
      background: ${color(background)};
    `};
`;
