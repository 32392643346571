import Box from 'components/Box/Box';
import Image from 'components/Image/Image';
import Loader from 'components/Layout/Loader/Loader';
import Link from 'components/Link/Link';
import Price from 'components/Price/Price';
import TileTitle from 'components/Tile/TileTitle/TileTitle';
import { BREAKPOINTS } from 'constants/breakpoints';
import { useProduct } from 'features';
import useUpdateCart from 'hooks/useUpdateCart';
import { ReactNode } from 'react';
import { ITileProduct } from 'types/ContentfulTypes';
import { DEFAULT_SIZE_KEY, ImageSizes } from 'types/Image';
import ContentfulImageBlock from '../ContentfulImageBlock/ContentfulImageBlock';
import {
  BannerContent,
  BannerImage,
  ProductInfoWrapper,
  StyledAddToCartButton,
  StyledImageWrapper,
} from './ContentfulProductBanner.styled';

interface ContentfulProductBannerProps {
  bannerSizes?: ImageSizes;
  className?: string;
  content: ITileProduct;
  productSizes?: ImageSizes;
}

const ContentfulProductBanner = ({
  bannerSizes = {
    [BREAKPOINTS.XL]: 1000,
    [BREAKPOINTS.LG]: 875,
    [BREAKPOINTS.MD]: 720,
    [BREAKPOINTS.SM]: 741,
    [DEFAULT_SIZE_KEY]: 400,
  },
  className,
  content,
  productSizes = {
    [BREAKPOINTS.SM]: 150,
    [DEFAULT_SIZE_KEY]: 200,
  },
}: ContentfulProductBannerProps) => {
  const { addToCart } = useUpdateCart();

  const { background: contentfulBackground, image: contentfulImage, product } = content.fields;

  const { data: productFull, isLoading } = useProduct({ code: product?.activeItems?.[0]?.code });

  const productImage = productFull?.images?.[0];

  if (!productFull) {
    return null;
  }

  const ProductLinkMapper = ({ children }: { children: ReactNode }) => {
    if (productFull) {
      return <Link url={productFull?.url}>{children}</Link>;
    }

    return <>{children}</>;
  };

  return (
    <Box className={className} paddingBottom={150} paddingTop={150} position="relative">
      <Loader position="absolute" show={isLoading} />
      <Box fullHeight fullWidth left={0} position="absolute" top={0}>
        <BannerImage content={contentfulBackground} sizes={bannerSizes} />
      </Box>
      <BannerContent>
        <ProductLinkMapper>
          <StyledImageWrapper>
            {contentfulImage ? (
              <ContentfulImageBlock
                content={contentfulImage}
                hasRelativeParent
                objectFit="contain"
                sizes={productSizes}
              />
            ) : (
              productImage?.url && (
                <Image
                  alt={productFull?.brand?.name}
                  layout="fill"
                  objectFit="contain"
                  sizes={productSizes}
                  src={productImage?.url}
                />
              )
            )}
          </StyledImageWrapper>
        </ProductLinkMapper>
        {productFull && (
          <ProductInfoWrapper>
            <TileTitle eyebrow={productFull.brand?.name} marginBottom={75} maxLines={2}>
              {productFull.name}
            </TileTitle>
            {productFull.price?.value && (
              <Price
                color="secondary-300"
                currentPrice={productFull.price.value}
                marginBottom={150}
                oldPrice={productFull.strikePrice?.value}
              />
            )}
            <StyledAddToCartButton onAddToCart={() => addToCart({ product: productFull })} showIcon />
          </ProductInfoWrapper>
        )}
      </BannerContent>
    </Box>
  );
};

export default ContentfulProductBanner;
