import Curve from 'components/Curve/Curve';
import Typography from 'components/Typography/Typography';
import { ICON_NAMES } from 'constants/icon';
import useWindowSize from 'hooks/useWindowSize';
import { IBanner } from 'types/ContentfulTypes';
import ContentfulPageLink from '../ContentfulPageLink/ContentfulPageLink';
import {
  BannerCardImage,
  BannerCardWrapper,
  BannerIcon,
  StyledBannerContentBlock,
  StyledCurveWrapper,
} from './ContentfulBannerCard.styled';

interface ContentfulBannerCardProps {
  className?: string;
  content: IBanner;
  hasCurve?: boolean;
  imageMinHeight?: string | number;
  priority?: boolean;
  scaleContent?: boolean;
}

const ContentfulBannerCard = ({
  className,
  content,
  hasCurve,
  imageMinHeight = '250px',
  priority,
  scaleContent,
}: ContentfulBannerCardProps) => {
  const { button, copy, mediumImage, mobileImage, title, wideImage } = content.fields;

  const link = button?.fields?.link;

  const { width = 0 } = useWindowSize();

  const body = (
    <BannerCardWrapper className={className}>
      <div>
        <BannerCardImage
          minHeight={imageMinHeight}
          mediumImage={mediumImage}
          mobileImage={mobileImage}
          mobileWidth={width / 2}
          priority={priority}
          wideImage={wideImage}
        />
      </div>

      {(title || link) && (
        <StyledBannerContentBlock fullHeight={scaleContent} gap={50}>
          {hasCurve && (
            <StyledCurveWrapper>
              <Curve />
            </StyledCurveWrapper>
          )}
          {title && (
            <Typography fontWeight="bold" maxLines={2}>
              {title}
            </Typography>
          )}
          {copy && <Typography maxLines={3}>{copy}</Typography>}
          {link && <BannerIcon type="fa" color="primary-300" name={ICON_NAMES.ANGLE_RIGHT} size={100} />}
        </StyledBannerContentBlock>
      )}
    </BannerCardWrapper>
  );

  if (link) {
    return <ContentfulPageLink link={link}>{body}</ContentfulPageLink>;
  }

  return body;
};

export default ContentfulBannerCard;
